import React from "react";
import Swiper from "react-id-swiper";

const Autoplay = () => {
    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    };

    return (
        <Swiper {...params}>
            <div className="slide4 slide-content">
                <h1>Contabilidad automatizada para tu empresa</h1>
            </div>
            <div className="slide5 slide-content">
                <h1>Cuenta de cliente para descargar todos los acuses y recibos timbrados, y mucho mas</h1>
            </div>
            <div className="slide1 slide-content">
                <h1>Asesoría Fiscal Contable</h1>
            </div>
            <div className="slide2 slide-content">
                <h1>Cálculo y timbrado de nómina</h1>
            </div>
            <div className="slide3 slide-content">
                <h1>Administración de clientes, proveedores y empleados</h1>
            </div>
        </Swiper>
    );
};

export default Autoplay;

